var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('paged-table-view',{attrs:{"search-function":_vm.fetchData,"query-form":_vm.queryForm},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"clearable":"","placeholder":"企业名称","filterable":""},model:{value:(_vm.queryForm.enterprise),callback:function ($$v) {_vm.$set(_vm.queryForm, "enterprise", $$v)},expression:"queryForm.enterprise"}},_vm._l((_vm.enterpriseList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.enterpriseName,"value":item.enterpriseName}})}),1)],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请填写员工名称","clearable":""},model:{value:(_vm.queryForm.realName),callback:function ($$v) {_vm.$set(_vm.queryForm, "realName", $$v)},expression:"queryForm.realName"}})],1),_c('el-form-item',[_c('el-date-picker',{attrs:{"placeholder":"开始时间","clearable":""},model:{value:(_vm.queryForm.startTime),callback:function ($$v) {_vm.$set(_vm.queryForm, "startTime", $$v)},expression:"queryForm.startTime"}})],1),_c('el-form-item',[_c('el-date-picker',{attrs:{"placeholder":"结束时间","clearable":""},model:{value:(_vm.queryForm.endTime),callback:function ($$v) {_vm.$set(_vm.queryForm, "endTime", $$v)},expression:"queryForm.endTime"}})],1)]},proxy:true},{key:"other-buttons",fn:function(){return [_c('export-button',{attrs:{"url":"/api/services/app/Payroll/Export","file-title":"薪资社保","query-model":_vm.queryForm,"button-title":"导出"}})]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"type":"index","width":"50","label":"序号"}}),_c('el-table-column',{attrs:{"label":"标题"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.title)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"企业名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.enterprise.enterpriseName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"员工名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.realName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"岗位薪资"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.basicSalary)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"税后薪资"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.afterTaxSalary)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"绩效奖励"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.performance)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"社保金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.socialInsurance)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"公积金"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.publicHousingFunds)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"备注"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.remark)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"creationTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(row.creationTime)))])]}}])}),_c('el-table-column',{attrs:{"label":"确认状态","prop":"sex"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.status == 'NotConfirm' ? 'danger' : 'primary'}},[_vm._v(_vm._s(scope.row.status == "NotConfirm" ? "未确认" : "已确认"))])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }